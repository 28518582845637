import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Container, Button } from "reactstrap";
import errorRequest from "common/errorRequest";
import "./styles.scss";

import api from "config/api";

import { CustomBootContext } from "../index";

const typePieceList = {
  Sola: "1",
  Cano: "2",
  BordadoCano: "3",
  BordadoGaspea: "4",
  Gaspea: "5",
};

export default function ModalUploadGaspea({ isOpen, toggleModal }) {
  const history = useHistory();
  const {
    setLoading,
    activeTab,
    setSolaImages,
    setCanoImages,
    setBordadoCanoImages,
    setBordadoGaspeaImages,
  } = useContext(CustomBootContext);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});

  function addImage(event) {
    event.preventDefault();

    let file = event.target.files[0];

    setImage({
      name: file.name,
      file,
      urlImage: URL.createObjectURL(file),
    });
  }

  async function uploadImages() {
    setLoading(true);

    try {
      const form = new FormData();

      form.append("file", image.file, image.name);
      form.append("description", description);
      form.append("idTypePiece", activeTab);

      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: `text/plain`,
      };

      const { data } = await api.post(`/image`, form, { headers });

      switch (activeTab) {
        case typePieceList.Sola:
          setSolaImages((solaImages) => [...solaImages, data.data]);
          break;
        case typePieceList.Cano:
          setCanoImages((canoImages) => [...canoImages, data.data]);
          break;
        case typePieceList.BordadoCano:
          setBordadoCanoImages((bordadoCanoImages) => [
            ...bordadoCanoImages,
            data.data,
          ]);
          break;
        case typePieceList.BordadoGaspea:
          setBordadoGaspeaImages((bordadoGaspeaImages) => [
            ...bordadoGaspeaImages,
            data.data,
          ]);
          break;
        default:
          break;
      }

      setImage({});
      setDescription("");
      toggleModal();

      Swal.fire("Sucesso", "Imagens Enviadas corretamente", "success").then(
        () => {
          toggleModal();
        }
      );
    } catch (error) {
      errorRequest(history, error);
    }

    setLoading(false);
  }

  return (
    <Modal
      size="lg"
      className="modal-image"
      toggle={() => toggleModal()}
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h3 className="modal-title">Fotos do Produto</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Container>
          <Row className="image-update">
            <Col md={6}>
              <span className="img-desc">Imagem da Peça</span>
              <input
                className="fileInput mt-3"
                type="file"
                onChange={(e) => addImage(e, true)}
              />
              <div className="imgPreview">
                {image && image.urlImage ? (
                  <div>
                    <img src={image.urlImage} alt="..." />
                  </div>
                ) : (
                  <div>Por favor selecione uma imagem</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <div className="col col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="formDescProductLateral">Descrição da peça</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formDescProductLateral"
                    placeholder="Ex: Cano Azul"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </Row>
          <Button
            onClick={() => uploadImages()}
            color="primary"
            className="mt-3 float-right"
          >
            Enviar
          </Button>
          <Button
            onClick={() => toggleModal()}
            color="danger"
            outline
            className="mt-3 mr-3 float-right"
          >
            Fechar
          </Button>
        </Container>
      </div>
    </Modal>
  );
}
