import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import "./styles.scss";

import { CustomBootContext } from "../index";

import errorRequest from "common/errorRequest";

import api from "config/api";

const typePieceList = {
  Sola: "1",
  Cano: "2",
  BordadoCano: "3",
  BordadoGaspea: "4",
  Gaspea: "5",
};

export default function ProductTabs() {
  const {
    setActiveTab,
    setLoading,
    activeTab,
    setGaspeaImages,
    setSolaImages,
    setCanoImages,
    setBordadoCanoImages,
    setBordadoGaspeaImages,
    gaspeaImages,
    solaImages,
    canoImages,
    bordadoCanoImages,
    bordadoGaspeaImages,
    setUploadImageModal,
    setUploadGaspeaModal,
    setIndexCano,
    setIndexGaspea,
    setIndexSola,
    setOpacitySwiperCano,
    setOpacitySwiperGaspea,
    setOpacitySwiperSola,
    setIsEnabled,
    canoActive,
    setCanoActive,
    gaspeaActive,
    setGaspeaActive,
    solaActive,
    setSolaActive,
    bordadoCanoActive,
    setBordadoCanoActive,
    bordadoGaspeaActive,
    setBordadoGaspeaActive,
  } = useContext(CustomBootContext);
  const history = useHistory();
  const [isTab, setIsTab] = useState(true);
  const [isPill, setIsPill] = useState(false);

  useEffect(() => {
    fetchProduct();
  }, []);

  async function fetchProduct() {
    setLoading(true);
    try {
      tabOrPill();
      const { data } = await api.get(`/product`);
      setGaspeaImages(data.data.gaspeaImages);
      setSolaImages(data.data.solaImages);
      setCanoImages(data.data.canoImages);
      setBordadoCanoImages(data.data.bordadoCanoImages);
      setBordadoGaspeaImages(data.data.bordadoGaspeaImages);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function tabOrPill() {
    const width = window.innerWidth;
    if (width <= 992) {
      setIsPill(true);
      setIsTab(false);
    }
  }

  function toggleModal() {
    setUploadImageModal((uploadImageModal) => !uploadImageModal);
  }

  function toggleGaspeaModal() {
    setUploadGaspeaModal((uploadGaspeaModal) => !uploadGaspeaModal);
  }

  async function deleteGaspeaImage(event, idGaspeaImage) {
    event.preventDefault();
    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente excluir essa Imagem?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua ele!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.delete(`/gaspeaImage/${idGaspeaImage}`);
          Swal.fire(
            "Deletado!",
            "A imagem foi excluida com sucesso",
            "success"
          );
          setGaspeaImages((gaspeaImages) =>
            gaspeaImages.filter((x) => x.idGaspeaImage !== idGaspeaImage)
          );
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  async function deleteImage(event, idImage, idTypePiece) {
    event.preventDefault();
    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente excluir essa Imagem?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua ele!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.delete(`/image/${idImage}`);
          Swal.fire(
            "Deletado!",
            "A imagem foi excluida com sucesso",
            "success"
          );
          switch (idTypePiece) {
            case typePieceList.Sola:
              setSolaImages((solaImages) =>
                solaImages.filter((x) => x.idImage !== idImage)
              );
              break;
            case typePieceList.Cano:
              setCanoImages((canoImages) =>
                canoImages.filter((x) => x.idImage !== idImage)
              );
              break;
            case typePieceList.BordadoCano:
              setBordadoCanoImages((bordadoCanoImages) =>
                bordadoCanoImages.filter((x) => x.idImage !== idImage)
              );
              break;
            case typePieceList.BordadoGaspea:
              setBordadoGaspeaImages((bordadoGaspeaImages) =>
                bordadoGaspeaImages.filter((x) => x.idImage !== idImage)
              );
              break;
            default:
              break;
          }
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  function enableCano() {
    setActiveTab(typePieceList.Cano);
    setIndexCano(200);
    setIndexGaspea(1);
    setIndexSola(1);
    setOpacitySwiperCano("100%");
    setOpacitySwiperGaspea("25%");
    setOpacitySwiperSola("25%");
    setIsEnabled(true);
    setCanoActive(true);
    setGaspeaActive(false);
    setSolaActive(false);
    setBordadoCanoActive(false);
    setBordadoGaspeaActive(false);
  }

  function enableGaspea() {
    setActiveTab(typePieceList.Gaspea);
    setIndexGaspea(200);
    setIndexCano(1);
    setIndexSola(1);
    setOpacitySwiperGaspea("100%");
    setOpacitySwiperCano("25%");
    setOpacitySwiperSola("25%");
    setIsEnabled(true);
    setCanoActive(false);
    setGaspeaActive(true);
    setSolaActive(false);
    setBordadoCanoActive(false);
    setBordadoGaspeaActive(false);
  }

  function enableSola() {
    setActiveTab(typePieceList.Sola);
    setIndexSola(200);
    setIndexCano(1);
    setIndexGaspea(1);
    setOpacitySwiperSola("100%");
    setOpacitySwiperGaspea("25%");
    setOpacitySwiperCano("25%");
    setIsEnabled(true);
    setCanoActive(false);
    setGaspeaActive(false);
    setSolaActive(true);
    setBordadoCanoActive(false);
    setBordadoGaspeaActive(false);
  }

  function enableBordadoCano() {
    setActiveTab(typePieceList.BordadoCano);
    setIndexSola(1);
    setIndexCano(1);
    setIndexGaspea(1);
    setOpacitySwiperSola("100%");
    setOpacitySwiperGaspea("100%");
    setOpacitySwiperCano("100%");
    setIsEnabled(false);
    setCanoActive(false);
    setGaspeaActive(false);
    setSolaActive(false);
    setBordadoCanoActive(true);
    setBordadoGaspeaActive(false);
  }

  function enableBordadoGaspea() {
    setActiveTab(typePieceList.BordadoGaspea);
    setIndexSola(1);
    setIndexCano(1);
    setIndexGaspea(1);
    setOpacitySwiperSola("100%");
    setOpacitySwiperGaspea("100%");
    setOpacitySwiperCano("100%");
    setIsEnabled(false);
    setCanoActive(false);
    setGaspeaActive(false);
    setSolaActive(false);
    setBordadoCanoActive(false);
    setBordadoGaspeaActive(true);
  }

  return (
    <div className="col-12 col-lg-6 mb-2">
      <Row className="justify-content-center">
        <Nav tabs={isTab} pills={isPill} className="mb-4 mr-3">
          <NavItem>
            <NavLink
              active={canoActive}
              style={{ cursor: "pointer" }}
              onClick={() => {
                enableCano();
              }}
            >
              Cano
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={gaspeaActive}
              style={{ cursor: "pointer" }}
              onClick={() => {
                enableGaspea();
              }}
            >
              Gáspea
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={solaActive}
              style={{ cursor: "pointer" }}
              onClick={() => {
                enableSola();
              }}
            >
              Sola
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={bordadoCanoActive}
              style={{ cursor: "pointer" }}
              onClick={() => enableBordadoCano()}
            >
              Bord. Cano
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={bordadoGaspeaActive}
              style={{ cursor: "pointer" }}
              onClick={() => enableBordadoGaspea()}
            >
              Bord. Gáspea
            </NavLink>
          </NavItem>
        </Nav>
      </Row>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {activeTab === typePieceList.Gaspea && (
          <Button
            className="my-2 col-12 col-md-auto"
            color="success"
            onClick={() => toggleGaspeaModal()}
          >
            Adicionar foto
          </Button>
        )}
        {activeTab !== typePieceList.Gaspea && (
          <Button
            className="my-2 col-12 col-md-auto"
            color="success"
            onClick={() => toggleModal()}
          >
            Adicionar foto
          </Button>
        )}
      </div>
      <Row className="justify-content-center">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={typePieceList.Cano}>
            <div className="container-images row-images-tabs row">
              {canoImages &&
                canoImages.length > 0 &&
                canoImages.map((image) => (
                  <div
                    key={image.urlImagePiece}
                    className="images-container-others row"
                  >
                    <img
                      className="images"
                      src={image.urlImagePiece}
                      alt="img-produto"
                    />
                    <Button
                      className="close"
                      onClick={(e) =>
                        deleteImage(e, image.idImage, typePieceList.Cano)
                      }
                    >
                      <span className="text-danger">×</span>
                    </Button>
                    <span className="desc-part">{image.description}</span>
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane tabId={typePieceList.Gaspea}>
            <div className="container-images">
              {gaspeaImages &&
                gaspeaImages.length > 0 &&
                gaspeaImages.map((gaspeaImage) => (
                  <div
                    key={gaspeaImage.urlGaspeaImageFront}
                    className="images-container-gaspea"
                  >
                    <Row className="justify-content-center">
                      <img
                        className="images"
                        src={gaspeaImage.urlGaspeaImageSide}
                        alt="img-produto"
                      />
                      <img
                        className="images"
                        src={gaspeaImage.urlGaspeaImageFront}
                        alt="img-produto"
                      />
                    </Row>
                    <Button
                      className="close"
                      onClick={(e) =>
                        deleteGaspeaImage(e, gaspeaImage.idGaspeaImage)
                      }
                    >
                      <span className="text-danger">×</span>
                    </Button>
                    <span className="desc-part">{gaspeaImage.description}</span>
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane tabId={typePieceList.Sola}>
            <div className="container-images row-images-tabs row">
              {solaImages &&
                solaImages.length > 0 &&
                solaImages.map((image) => (
                  <div
                    key={image.urlImagePiece}
                    className="images-container-others row"
                  >
                    <img
                      className="images"
                      src={image.urlImagePiece}
                      alt="img-produto"
                    />
                    <Button
                      className="close"
                      onClick={(e) =>
                        deleteImage(e, image.idImage, typePieceList.Sola)
                      }
                    >
                      <span className="text-danger">×</span>
                    </Button>
                    <span className="desc-part">{image.description}</span>
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane tabId={typePieceList.BordadoCano}>
            <div className="container-images row-images-tabs row">
              {bordadoCanoImages &&
                bordadoCanoImages.length > 0 &&
                bordadoCanoImages.map((image) => (
                  <div
                    key={image.urlImagePiece}
                    className="images-container-others row"
                  >
                    <img
                      className="images"
                      src={image.urlImagePiece}
                      alt="img-produto"
                    />
                    <Button
                      className="close"
                      onClick={(e) =>
                        deleteImage(e, image.idImage, typePieceList.BordadoCano)
                      }
                    >
                      <span className="text-danger">×</span>
                    </Button>
                    <span className="desc-part">{image.description}</span>
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane tabId={typePieceList.BordadoGaspea}>
            <div className="container-images row-images-tabs row">
              {bordadoGaspeaImages &&
                bordadoGaspeaImages.length > 0 &&
                bordadoGaspeaImages.map((image) => (
                  <div
                    key={image.urlImagePiece}
                    className="images-container-others row"
                  >
                    <img
                      className="images"
                      src={image.urlImagePiece}
                      alt="img-produto"
                    />
                    <Button
                      className="close"
                      onClick={(e) =>
                        deleteImage(
                          e,
                          image.idImage,
                          typePieceList.BordadoGaspea
                        )
                      }
                    >
                      <span className="text-danger">×</span>
                    </Button>
                    <span className="desc-part">{image.description}</span>
                  </div>
                ))}
            </div>
          </TabPane>
        </TabContent>
      </Row>
    </div>
  );
}
