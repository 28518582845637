import React, { useState } from "react";

import "./styles.scss";
import { Row } from "reactstrap";

import Page from "components/Page";

import ModalUploadGaspea from "./ModalUploadGaspea";
import ModalUploadImage from "./ModalUploadImage";
import ProductTabs from "./ProductTabs/index";
import CustomSlider from "./CustomSlider";

const CustomBootContext = React.createContext([{}, () => {}]);

const typePieceList = {
  Sola: "1",
  Cano: "2",
  BordadoCano: "3",
  BordadoGaspea: "4",
  Gaspea: "5",
};

function CustomBootInfo() {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(typePieceList.Cano);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [uploadGaspeaModal, setUploadGaspeaModal] = useState(false);
  const [gaspeaImages, setGaspeaImages] = useState([]);
  const [solaImages, setSolaImages] = useState([]);
  const [canoImages, setCanoImages] = useState([]);
  const [bordadoCanoImages, setBordadoCanoImages] = useState([]);
  const [bordadoGaspeaImages, setBordadoGaspeaImages] = useState([]);
  const [indexCano, setIndexCano] = useState(200);
  const [indexGaspea, setIndexGaspea] = useState(1);
  const [indexSola, setIndexSola] = useState(1);
  const [opacitySwiperCano, setOpacitySwiperCano] = useState("100%");
  const [opacitySwiperGaspea, setOpacitySwiperGaspea] = useState("100%");
  const [opacitySwiperSola, setOpacitySwiperSola] = useState("100%");
  const [isEnabled, setIsEnabled] = useState(true);
  const [canoActive, setCanoActive] = useState(true);
  const [gaspeaActive, setGaspeaActive] = useState(false);
  const [solaActive, setSolaActive] = useState(false);
  const [bordadoCanoActive, setBordadoCanoActive] = useState(false);
  const [bordadoGaspeaActive, setBordadoGaspeaActive] = useState(false);

  return (
    <CustomBootContext.Provider
      value={{
        loading,
        setLoading,
        activeTab,
        setActiveTab,
        gaspeaImages,
        setGaspeaImages,
        solaImages,
        setSolaImages,
        canoImages,
        setCanoImages,
        bordadoCanoImages,
        setBordadoCanoImages,
        bordadoGaspeaImages,
        setBordadoGaspeaImages,
        uploadImageModal,
        setUploadImageModal,
        uploadGaspeaModal,
        setUploadGaspeaModal,
        indexCano,
        setIndexCano,
        indexGaspea,
        setIndexGaspea,
        indexSola,
        setIndexSola,
        opacitySwiperCano,
        setOpacitySwiperCano,
        opacitySwiperGaspea,
        setOpacitySwiperGaspea,
        opacitySwiperSola,
        setOpacitySwiperSola,
        isEnabled,
        setIsEnabled,
        canoActive,
        setCanoActive,
        gaspeaActive,
        setGaspeaActive,
        solaActive,
        setSolaActive,
        bordadoCanoActive,
        setBordadoCanoActive,
        bordadoGaspeaActive,
        setBordadoGaspeaActive,
      }}
    >
      <Page title="Bem-vindo(a) ao Bota Personalizada" loading={loading} back>
        <>
          <ModalUploadImage
            isOpen={uploadImageModal}
            toggleModal={() =>
              setUploadImageModal((uploadImageModal) => !uploadImageModal)
            }
          />
          <ModalUploadGaspea
            isOpen={uploadGaspeaModal}
            toggleModal={() =>
              setUploadGaspeaModal((uploadGaspeaModal) => !uploadGaspeaModal)
            }
          />
        </>
        <>
          <Row>
            <CustomSlider />
            <ProductTabs />
          </Row>
        </>
      </Page>
    </CustomBootContext.Provider>
  );
}

export { CustomBootContext, CustomBootInfo };
