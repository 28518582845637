import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import swalError from "common/swalError";

import { Col, Row, Button, Input } from "reactstrap";

import "./styles.scss";

import { CustomBootContext } from "../index";

import errorRequest from "common/errorRequest";

import api from "config/api";

export default function () {
  const { setLoading } = useContext(CustomBootContext);
  const [productPrice, setProductPrice] = useState("");
  const [promotionalPrice, setPromotionalPrice] = useState("");
  const [variations, setVariations] = useState([]);
  const [variationsDeleted, setVariationsDeleted] = useState([]);
  const [variationNumber, setVariationNumber] = useState("");

  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.get(`/variation`);
      setProductPrice(data.data.productPrice);
      setPromotionalPrice(data.data.promotionalPrice);
      setVariations(data.data.Variations);
    } catch (error) {
      errorRequest(history, error);
      setLoading(false);
    }
    setLoading(false);
    return;
  }

  function checkSpecs() {
    if (!productPrice || variations.length <= 0) {
      Swal.fire(
        "Atenção!",
        "Alguns campos não foram preenchidos, verifique!",
        "warning"
      );
      return;
    }

    if (isNaN(promotionalPrice) || promotionalPrice > productPrice) {
      Swal.fire(
        "Atenção!",
        "Preço promocional inválido. Certifique-se que ele seja menor ou igual o preço original",
        "warning"
      );
      setPromotionalPrice(productPrice);
      return;
    }

    if (promotionalPrice === 0 || productPrice === 0 || isNaN(productPrice)) {
      Swal.fire(
        "Atenção!",
        "Preço do produto ou promocional tem que ser maior que zero.",
        "warning"
      );
      return;
    }

    saveSpecs();
  }

  async function saveSpecs() {
    setLoading(true);
    try {
      await api.post(`/variation`, {
        productPrice,
        promotionalPrice,
        variations: [...variations, ...variationsDeleted],
      });
      Swal.fire("Sucesso!", "As definições da bota foram salvas!", "success");
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);

    setVariationsDeleted([]);

    variations.forEach((variation) => {
      variation.created = false;
    });
  }

  async function addVariation(event) {
    event.preventDefault();
    if (
      variationNumber < 33 ||
      variationNumber > 45 ||
      isNaN(variationNumber)
    ) {
      Swal.fire("Atenção!", "A numeração deve ser entre 33 e 45", "warning");
      return;
    }

    if (variations.find((x) => x.value === variationNumber)) {
      Swal.fire(
        "Atenção!",
        "Não é possível inserir o mesmo número duas vezes",
        "warning"
      );
      return;
    }

    const variNum = {
      value: variationNumber,
      deleted: false,
      created: true,
    };

    setVariationNumber("");
    setVariations((variations) => [...variations, variNum]);
  }

  function deleteVariation(event, index) {
    event.preventDefault();

    const variation = variations[index];
    if (variation.idVariation) {
      variation.deleted = true;
      setVariationsDeleted((variationsDeleted) => [
        ...variationsDeleted,
        variation,
      ]);
    }
    setVariations((variations) => [
      ...variations.filter((variation, i) => i !== index),
    ]);
  }

  return (
    <Col>
      <Row className="mt-4">
        <Col className="col-12 col-lg-6">
          <label htmlFor="price-default-input">
            Defina o preço padrão das botas
          </label>
          <div className="input-group mb-3">
            <div className="input-group-append">
              <span className="input-group-text prefix-price">R$</span>
            </div>
            <Input
              type="number"
              className="form-control price-default"
              id="price-default-input"
              placeholder="Preço padrão"
              aria-label="Preço padrão"
              onChange={(e) => {
                setProductPrice(parseFloat(e.target.value));
              }}
              value={productPrice}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-lg-6">
          <label htmlFor="price-promo-input">
            Defina o preço promotional das botas
            <span className="optional-warning"> (Não obrigatório)</span>
          </label>
          <div className="input-group mb-3">
            <div className="input-group-append">
              <span className="input-group-text prefix-price">R$</span>
            </div>
            <Input
              type="number"
              className="form-control price-default"
              id="price-promo-input"
              placeholder="Preço promotional"
              aria-label="Preço promotional"
              onChange={(e) => {
                setPromotionalPrice(parseFloat(e.target.value));
              }}
              value={promotionalPrice}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-lg-6">
          <label htmlFor="price-promo-input">
            Defina a numeração das botas
          </label>
          <div className="input-group mb-3">
            <div className="input-group-append">
              <span className="input-group-text prefix-price">Nº</span>
            </div>
            <Input
              type="number"
              className="form-control price-default"
              id="price-promo-input"
              placeholder="Tamanho do par"
              aria-label="Tamanho do par"
              onChange={(e) => setVariationNumber(e.target.value)}
              value={variationNumber}
            />
            <div className="input-group-append">
              <Button
                className="btn btn-success button-price"
                type="button"
                onClick={(e) => addVariation(e)}
              >
                Adicionar
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="variacao-numeros">
            <span className="boldGp">Números: </span>
            <Row>
              {variations ? (
                <>
                  {variations &&
                    variations.length > 0 &&
                    variations
                      .sort((x, y) => x.value - y.value)
                      .map((variation, index) => (
                        <div
                          className="number-wrapper col-3 col-lg-2 col-xl-1"
                          key={index}
                        >
                          <span className="ml-1 badge badge-pill numberBadge badge-primary">
                            {variation.value}
                          </span>
                          <span
                            className="number-exclusion"
                            role="img"
                            aria-label="emoji de exclusão"
                            onClick={(e) => deleteVariation(e, index)}
                          >
                            ❌
                          </span>
                        </div>
                      ))}
                </>
              ) : (
                `Numeração não definida`
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <Button
            className="my-2 col-12 col-md-auto float-right"
            color="success"
            onClick={() => checkSpecs()}
          >
            Salvar
          </Button>
        </Col>
        <Col className="col-12 col-md-6 float-right">
          <Button
            className="my-2 col-12 col-md-auto float-right"
            color="primary"
            min="33"
            max="45"
            onClick={() => history.push("/admin/customBoot")}
          >
            Gerenciar peças da bota
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
