import React, { useState } from "react";

import "./styles.scss";
import { Row } from "reactstrap";

import Page from "components/Page";
import Loading from "components/Loading";

import BootSpecs from "./BootSpecs";

const CustomBootContext = React.createContext([{}, () => {}]);

const typePieceList = {
  Sola: "1",
  Cano: "2",
  BordadoCano: "3",
  BordadoGaspea: "4",
  Gaspea: "5",
};

function CustomBoot() {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(typePieceList.Cano);
  const [isEnabled, setIsEnabled] = useState(true);
  const [canoActive, setCanoActive] = useState(true);
  const [gaspeaActive, setGaspeaActive] = useState(false);
  const [solaActive, setSolaActive] = useState(false);
  const [bordadoCanoActive, setBordadoCanoActive] = useState(false);
  const [bordadoGaspeaActive, setBordadoGaspeaActive] = useState(false);

  return (
    <CustomBootContext.Provider
      value={{
        loading,
        setLoading,
        activeTab,
        setActiveTab,
        isEnabled,
        setIsEnabled,
        canoActive,
        setCanoActive,
        gaspeaActive,
        setGaspeaActive,
        solaActive,
        setSolaActive,
        bordadoCanoActive,
        setBordadoCanoActive,
        bordadoGaspeaActive,
        setBordadoGaspeaActive,
      }}
    >
      <Page title="Bem-vindo(a) ao Botas Personalizadas" loading={loading}>
        {loading && <Loading />}
        <Row>
          <BootSpecs />
        </Row>
      </Page>
    </CustomBootContext.Provider>
  );
}

export { CustomBootContext, CustomBoot };
