import UserProfile from "views/admin/UserProfile";
import { CustomBootInfo } from "views/admin/CustomBootInfo/index.jsx";
import { CustomBoot } from "views/admin/CustomBoot/index.jsx";
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Valores e numeração",
    icon: "fas fa-edit",
    component: CustomBoot,
    layout: "/admin",
  },
  {
    path: "/customBoot",
    name: "Bota personalizada",
    icon: "fas fa-shoe-prints",
    component: CustomBootInfo,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
