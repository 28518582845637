import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Container, Button } from "reactstrap";
import errorRequest from "common/errorRequest";
import "./styles.scss";

import api from "config/api";

import { CustomBootContext } from "../index";

export default function ModalUploadGaspea({ isOpen, toggleModal }) {
  const history = useHistory();
  const { setLoading, setGaspeaImages } = useContext(CustomBootContext);
  const [imageGaspeaSide, setImageGaspeaSide] = useState({});
  const [imageGaspeaFront, setImageGaspeaFront] = useState({});
  const [description, setDescription] = useState("");

  function addImage(event, front) {
    event.preventDefault();

    let file = event.target.files[0];

    if (front)
      setImageGaspeaFront({
        name: file.name,
        file,
        urlGaspeaImageFront: URL.createObjectURL(file),
      });
    else
      setImageGaspeaSide({
        name: file.name,
        file,
        urlGaspeaImageSide: URL.createObjectURL(file),
      });
  }

  async function uploadImagesGaspea() {
    setLoading(true);

    try {
      const form = new FormData();

      form.append("files", imageGaspeaSide.file);
      form.append("files", imageGaspeaFront.file);
      form.append("description", description);

      const headers = {
        "Content-Type": "multipart/form-data",
        Accept: `text/plain`,
      };

      const { data } = await api.post(`/gaspeaImage`, form, { headers });
      setImageGaspeaSide({});
      setImageGaspeaFront({});
      setDescription("");
      setGaspeaImages((gaspeaImages) => [...gaspeaImages, data.data]);
      toggleModal();

      Swal.fire("Sucesso", "Imagens Enviadas corretamente", "success").then(
        () => {
          toggleModal();
        }
      );
    } catch (error) {
      errorRequest(history, error);
    }

    setLoading(false);
  }

  return (
    <Modal
      size="lg"
      className="modal-gaspea-image"
      toggle={() => toggleModal()}
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h3 className="modal-title">Fotos do Produto</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Container>
          <Row className="image-update">
            <Col md={6}>
              <span className="img-desc">Imagem lateral</span>
              <input
                className="fileInput mt-3"
                type="file"
                onChange={(e) => addImage(e, false)}
              />
              <div className="imgPreview">
                {imageGaspeaSide && imageGaspeaSide.urlGaspeaImageSide ? (
                  <div>
                    <img src={imageGaspeaSide.urlGaspeaImageSide} alt="..." />
                  </div>
                ) : (
                  <div>Por favor selecione uma imagem</div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <span className="img-desc">Imagem frontal</span>
              <input
                className="fileInput mt-3"
                type="file"
                onChange={(e) => addImage(e, true)}
              />
              <div className="imgPreview">
                {imageGaspeaSide && imageGaspeaFront.urlGaspeaImageFront ? (
                  <div>
                    <img src={imageGaspeaFront.urlGaspeaImageFront} alt="..." />
                  </div>
                ) : (
                  <div>Por favor selecione uma imagem</div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <div className="col col-12">
              <form>
                <div className="form-group">
                  <label htmlFor="formDescProductLateral">
                    Descrição da peça
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formDescProductLateral"
                    placeholder="Ex: Gáspea Azul"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </Row>
          <Button
            onClick={() => uploadImagesGaspea()}
            color="primary"
            className="mt-3 float-right"
          >
            Enviar
          </Button>
          <Button
            onClick={() => toggleModal()}
            color="danger"
            outline
            className="mt-3 mr-3 float-right"
          >
            Fechar
          </Button>
        </Container>
      </div>
    </Modal>
  );
}
