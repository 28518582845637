import React, { useContext } from "react";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import "./styles.scss";

import { CustomBootContext } from "../index";

SwiperCore.use([Navigation]);

export default function CustomSlider() {
  const {
    indexCano,
    indexGaspea,
    indexSola,
    opacitySwiperCano,
    opacitySwiperGaspea,
    opacitySwiperSola,
    gaspeaImages,
    solaImages,
    canoImages,
  } = useContext(CustomBootContext);

  return (
    <div className="col-12 col-lg-6 mb-2">
      <div>
        {canoImages && canoImages.length > 0 && (
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={true}
            style={{
              zIndex: indexCano,
              opacity: opacitySwiperCano,
            }}
            className="swiperCano"
          >
            {canoImages.map((image) => (
              <SwiperSlide key={image.urlImagePiece}>
                <img
                  className="swiperImage"
                  src={image.urlImagePiece}
                  alt="img-cano-personalizado"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {gaspeaImages && gaspeaImages.length > 0 && (
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={true}
            style={{
              zIndex: indexGaspea,
              opacity: opacitySwiperGaspea,
            }}
            className="swiperGaspea"
          >
            {gaspeaImages.map((image) => (
              <SwiperSlide key={image.urlGaspeaImageSide}>
                <img
                  className="swiperImage"
                  src={image.urlGaspeaImageSide}
                  alt="img-gaspea-personalizada"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {solaImages && solaImages.length > 0 && (
          <Swiper
            slidesPerView={1}
            loop={true}
            navigation={true}
            style={{
              zIndex: indexSola,
              opacity: opacitySwiperSola,
            }}
            className="swiperSola"
          >
            {solaImages.map((image) => (
              <SwiperSlide key={image.urlImagePiece}>
                <img
                  className="swiperImage"
                  src={image.urlImagePiece}
                  alt="img-sola-personalizada"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
